import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";

import { FMgetGallaryPhotos } from "api/api";
import noContactImage from "assets/images/no-contact1.svg";
import { toast } from "react-toastify";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useErrorHandler } from "react-error-boundary";
import Layout from "components/Layout";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import loader from "assets/images/loader.gif"

export default function PhotosGallery() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  const lovedOne = useSelectedLovedOne();

  useEffect(() => {
    if (lovedOne && lovedOne.id) {
      setLoading(true);
      FMgetGallaryPhotos({
        lovedOneId: lovedOne.id,
      })
        .then((response) => {
          setList(response.data.data);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => setLoading(false));
    }
  }, [lovedOne && lovedOne.id]);

  const handleUploadImage = async (photos, previewUrls) => {
    try {
      if (photos.length > 0) {
        navigate("/FMCommentImage", {
          state: { photos, previewUrls, lovedOneId: lovedOne.id },
        });
      } else {
        toast("Please select only image", { type: "error" });
      }
    } catch (error) {
      toast(error, { type: "error" });
    }
  };

  const getPhotoList = () => {
    return list.length ? (
      list.map((item, i) => (
        <div key={item.id} className="col-6">
          <div className="photosBlog">
            <img
              src={item.photo}
              alt={item.description}
              title={item.description}
              onClick={() => {
                navigate(`/FMGallaryImage/${item.id}`);
              }}
            />
          </div>
        </div>
      ))
    ) : (
      <div className=" text-center nocontact">
        <img src={noContactImage} style={{ width: "25%" }} alt="no contact" />
        <h3 className="pt-4">No Photos Found</h3>
      </div>
    );
  };

  return (
    <Layout
      headerName="photos"
      backNavigate={"/MenuScreen"}
      hidePhoto
      noFixedHeader
    >
      <div className="parent">
        <div className="contentboxgray11">
          <br />
        </div>
      </div>
      <div className="contentBody contentbodymedia mtminus">
        <div className="profilebox mt-5">
          <div className="userprofile ">
            <img src={lovedOne.photo} className="profileimg" alt="" title="" />
          </div>
        </div>
        {/* <div className="profilTileHeading photo-gallery-text">
          
          <span></span>
        </div> */}

        <div className="text-center purpleColor bottomgape connectwithtexts">
          Photos shared with <br />
          <h3>{lovedOne.first_name}</h3>
        </div>

        <div className="row justify-content-center customprofilegallerybox">
          <UploadProfilePhoto gallery handleUpload={handleUploadImage} />
        </div>

        <div className="row imageupload text-center mb-5">
          {isLoading ? (
            // <Spinner animation="border" variant="primary" />
            <div className="loader-container">
              <img src={loader} alt="Loading..." className="custom-loader" />
            </div>

          ) : (
            getPhotoList()
          )}
        </div>
      </div>
    </Layout>
  );
}
